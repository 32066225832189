<template>
  <div>
    <span class="label">Rooms ({{ total }})</span>
    <div class="item_areas">
      <div
        v-for="(value, key, index) in itemAreas"
        :key="index"
        class="item_area"
      >
        <span>
          {{ formatToTitleCase(key).replace("Rooms", "Rooms /") }}
        </span>
        <span class="text-primary">{{ value }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderCleaningItemAreas",
  props: {
    itemAreas: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    total() {
      return this.itemAreas
        ? Object.keys(this.itemAreas).reduce(
            (acc, item) => acc + this.itemAreas[item],
            0
          )
        : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  color: var(--eden-grey-primary);
  font-weight: 500;
  font-size: 0.775rem;
}

.item_areas {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .item_area {
    background: #f0f4f2;
    border-radius: 8px;
    color: var(--eden-grey-secondary);
    font-size: 0.75rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;

    &:first-child {
      margin-bottom: 8px;
    }

    span {
      &:last-child {
        margin-left: 24px;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.extra-notes {
  h6 {
    margin-bottom: 12px;
  }
}
</style>
