import axios from "axios";

export default {
  types() {
    return axios.get("cleaning/items/all");
  },

  cleanersAssign(orderId, payload) {
    return axios.post(`crm/order/${orderId}/assign_cleaners`, payload);
  },
};
