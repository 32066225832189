<template>
  <div class="edit-order">
    <eden-overview-card :title="'Edit Order'">
      <template slot="action">
        <div class="edit-order__actions">
          <el-button @click="setCleaning"> Undo Changes </el-button>
          <el-button type="primary" :loading="updating" @click="updateOrder">
            Save Changes
          </el-button>
        </div>
      </template>

      <template slot="content">
        <div class="edit-order__banner" @click="$emit('toggle-edit')">
          <span class="font-sm">
            You are now editing this order.
            <span class="text-primary">
              Click here to exit edit mode without saving changes.
            </span>
          </span>
        </div>
        <div class="edit-order__item">
          <el-form :model="form" label-position="top" ref="form">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Cleaning Type"
                  prop="cleaning_type"
                  :rules="validateField()"
                >
                  <el-select
                    v-model="form.cleaning_type"
                    placeholder="Select"
                    :disabled="!allowAccessFor(['admin'])"
                  >
                    <el-option
                      v-for="(item, index) in cleaningTypes"
                      :key="index"
                      :label="item.name"
                      :value="formatSlug(item.name)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Service Partner">
                  <el-select
                    v-model.number="form.sp_cleaning"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="(sp, index) in cleaningServicePartners"
                      :key="index"
                      :label="sp.name"
                      :value="sp.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Assigned cleaners" prop="cleaners_id">
                  <el-select
                    v-model="form.cleaners_id"
                    multiple
                    filterable
                    value-key="id"
                    placeholder="Search cleaners"
                    :disabled="!allowAccessFor(['admin', 'operations'])"
                    @change="setPrimaryCleaner"
                  >
                    <el-option
                      v-for="(cleaner, index) in cleaners"
                      :key="index"
                      :label="`${cleaner.firstname} ${cleaner.lastname}`"
                      :value="cleaner"
                      >{{ formatFullName(cleaner) }}</el-option
                    >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <div class="cleaners">
                  <div
                    v-for="(cleaner, index) in form.cleaners_id"
                    :key="index"
                    class="el-selected-member"
                  >
                    <div class="name">
                      <eden-avatar :src="cleaner.avatar" :size="24" />
                      <span>{{ formatFullName(cleaner) }}</span>
                    </div>
                    <div class="actions">
                      <el-radio
                        v-model="form.primary_cleaner_id"
                        :label="cleaner.id"
                        >Set as primary cleaner</el-radio
                      >
                      <span class="close" @click="resetCleaners(cleaner.id)">
                        <i class="eden-icon-remove-circle" />
                      </span>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Address" prop="home_address">
                  <el-input
                    v-model="form.home_address"
                    type="textarea"
                    placeholder="Address"
                    rows="8"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <order-cleaning-item-areas :item-areas="cleaning.item_areas" />
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Cleaning notes">
                  <el-input
                    type="textarea"
                    placeholder="Address"
                    v-model="form.cleaning_notes"
                    rows="10"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
    </eden-overview-card>
  </div>
</template>
<script>
import OrderCleaningItemAreas from "@/components/Orders/Order/Cleaning/OrderCleaningItemAreas";

import cleaning from "@/requests/orders/cleaning";
import order from "@/requests/orders/order";
import schedule from "@/requests/logistics/schedule";

import cleaners from "@/requests/logistics/directory/cleaners";

export default {
  name: "OrderCleaningEdit",
  props: {
    orderId: {
      type: [String, Number],
      default: null,
    },
    customerId: {
      type: [String, Number],
      default: null,
    },
    cleaning: {
      type: Object,
      default() {
        return {};
      },
    },
    orderDate: {
      type: [String, Number],
      default: null,
    },
  },
  components: { OrderCleaningItemAreas },
  data() {
    return {
      form: {
        cleaning_type: "",
        sp_cleaning: null,
        sp_name: "",
        cleaners_id: [],
        primary_cleaner_id: "",
        home_address: "",
        item_areas: {},
        room_size: 0,
        cleaning_notes: "",
      },
      cleaningTypes: [],
      cleaners: [],
      updating: false,
    };
  },
  computed: {
    cleaningServicePartners() {
      return this.$store.getters.service_partners_list.cleaning;
    },
    spSpecifics() {
      const payload = [
        {
          serviceProviderID: this.cleaning.sp_cleaning,
          specifics: this.cleaning.cleaning_type,
        },
      ];
      return { sp_specifics: JSON.stringify(payload) };
    },
  },
  created() {
    this.setCleaners();
    this.setCleaningTypes();
    this.setCleaning();
  },
  methods: {
    setCleaners() {
      cleaners
        .list()
        .then((response) => {
          if (response.data.status) {
            this.cleaners = response.data.data;
          }
        })
        .catch();
    },
    setCleaningTypes() {
      cleaning
        .types()
        .then((response) => {
          if (response.data.status) {
            this.cleaningTypes = response.data.data;
          }
        })
        .catch();
    },
    setCleaning() {
      const cleaning = this.parseData(this.cleaning);
      Object.keys(this.form).forEach((key) => {
        this.form[key] = cleaning[key];
      });

      this.form.cleaners_id = this.cleaning.cleaners;
    },
    setPrimaryCleaner() {
      const cleaners = this.form.cleaners_id;
      if (cleaners.length) {
        let obj = cleaners[cleaners.length - 1];
        // console.log(obj);
        let payload = {
          user_id: obj.id,
          user_type: "cleaner",
          service_date: this.orderDate.slice(0, 10),
        };
        schedule
          .orderScheduleCount(payload)
          .then((response) => {
            const count = response.data.data.order_schedule_count;
            if (count) {
              this.$message.warning(
                `Cleaner ${obj.firstname} ${
                  obj.lastname
                } has already been assigned ${count} orders for ${this.formatDate(
                  this.orderDate,
                  "dddd",
                )}`,
              );
            }
          })
          .catch((error) => {
            const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          });
      }
      const primary_cleaner_id = this.form.cleaners_id.filter(
        (cleaner) =>
          parseInt(cleaner.id) === parseInt(this.form.primary_cleaner_id),
      );

      if (cleaners.length === 0) {
        this.form.primary_cleaner_id = "";
      }

      if (cleaners.length > 0 && !primary_cleaner_id.length) {
        this.form.primary_cleaner_id = cleaners[0].id;
      }
    },
    resetCleaners(id) {
      this.form.cleaners_id = this.form.cleaners_id.filter(
        (cleaner) => cleaner.id !== id,
      );
      this.setPrimaryCleaner();
    },
    updateOrder() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;

        const payload = {
          id: this.orderId,
          orderPayload: {
            ...this.form,
            cleaners_id: this.form.cleaners_id.map((cleaner) => cleaner.id),
            customer_id: this.customerId,
            service: "Cleaning",
          },
          spPayload: this.spSpecifics,
        };

        order
          .update(payload)
          .then((response) => {
            const orderUpdate = response[0].data;
            const spUpdate = response[1].data;
            if (orderUpdate.status && spUpdate.status) {
              this.$message.success("Order details updated successfully");
              this.updateCleaners();
              if (this.form.cleaning_notes) {
                this.updateCleaningPreferences();
              }
            }
            this.updating = false;
            this.$emit("updated", this.form);
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    updateCleaningPreferences() {
      const payload = {
        user_id: this.customerId,
        customer_order_id: this.orderId,
        service: "cleaning",
        general_preference: [this.form.cleaning_notes],
        specific_preference: "[]",
      };
      order
        .updateOrderPreferences(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success("Order preferences updated");
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateCleaners() {
      const prevCleaners = this.cleaning.cleaners_id.map((cleaner) => cleaner);
      const newCleaners = this.form.cleaners_id.map((cleaner) => cleaner.id);

      const prevPrimaryCleaner = this.cleaning.primary_cleaner_id;
      const newPrimaryCleaner = this.form.primary_cleaner_id;

      prevCleaners.sort((a, b) => (a < b ? -1 : 1));
      newCleaners.sort((a, b) => (a < b ? -1 : 1));

      const sameCleaners = prevCleaners.toString() === newCleaners.toString();
      const samePrimaryCleaner = prevPrimaryCleaner === newPrimaryCleaner;

      if (sameCleaners && samePrimaryCleaner) {
        return;
      }

      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        actor_id: logged_in_userId,
        cleaners_id: this.form.cleaners_id.map((cleaner) => cleaner.id),
        primary_cleaner_id: this.form.primary_cleaner_id,
      };

      cleaning
        .cleanersAssign(this.orderId, payload)
        .then((response) => {
          const { status } = response.data;
          if (status) {
            this.$message.success("Cleaners updated");
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cleaners {
  margin-bottom: 15px;
}
</style>
