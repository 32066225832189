<template>
  <div>
    <template v-if="!editing">
      <eden-overview-card :title="'Order Items'">
        <template slot="action">
          <el-button
            :type="'text'"
            :color="'primary'"
            :disabled="disableUpdate"
            @click="editing = true"
          >
            Edit Order
          </el-button>
        </template>
        <template slot="content">
          <el-collapse accordion>
            <eden-collapse-item
              :activities="cleaning.activities"
              :wrap-bar="false"
            >
              <template slot="summary">
                <span class="type">{{
                  formatToTitleCase(cleaning.cleaning_type)
                }}</span>
                <span class="status">{{
                  formatServiceActivity(order.last_marked_activity)
                }}</span>
              </template>
              <template slot="breakdown">
                <div>
                  <span class="font-sm text-grey-secondary">
                    {{ cleaning.home_address }}
                  </span>
                  <div class="mt-10">
                    <span v-if="cleanersAssigned" class="font-sm">
                      {{ cleanersAssigned }}
                    </span>
                    <span v-else class="font-sm text-uppercase text-danger">
                      No Cleaners Assigned
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    cleaning.item_areas &&
                    Object.keys(cleaning.item_areas).length
                  "
                  class="breakdown-section"
                >
                  <order-cleaning-item-areas
                    :item-areas="cleaning.item_areas"
                  />
                </div>
                <div class="breakdown-section">
                  <span class="type">Cleaning Notes</span>
                  <span class="font-sm" v-if="cleaning_notes.length">
                    <!-- <span v-for="(item,index) in cleaning_notes" :key="index">
                      {{ item.preference }}
                    </span> -->
                    <span>{{ cleaning_notes[0].preference }}</span>
                  </span>
                  <span v-else>-</span>
                </div>
              </template>
            </eden-collapse-item>
          </el-collapse>
        </template>
      </eden-overview-card>
    </template>
    <div v-else class="fadeIn">
      <order-cleaning-edit
        :order-id="orderId"
        :customer-id="customerId"
        :cleaning="cleaning"
        @toggle-edit="editing = false"
        @updated="updateCleaning"
        @get-order="$emit('get-order')"
        :order-date="orderDeliveryInformation.delivery_date"
      />
    </div>
  </div>
</template>
<script>
import OrderCleaningEdit from "@/components/Orders/Order/Cleaning/OrderCleaningEdit";
import order from "@/requests/orders/order";
import { CLEANING_ACTIVITIES } from "@/components/Orders/Order/Cleaning/cleaning.config";
import OrderCleaningItemAreas from "@/components/Orders/Order/Cleaning/OrderCleaningItemAreas";

export default {
  name: "OrderCleaning",
  components: {
    OrderCleaningItemAreas,
    OrderCleaningEdit,
  },
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    disableUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cleaning: {
        activities: CLEANING_ACTIVITIES(this.order),
      },
      editing: false,
      cleaning_notes: [],
    };
  },
  computed: {
    orderId() {
      return this.order.order_id;
    },
    customerId() {
      return this.order.customer_id;
    },
    cleaningServicePartners() {
      return this.$store.getters.service_partners_list.cleaning;
    },
    cleanersAssigned() {
      let cleaners = "";
      if (this.cleaning.cleaners.length) {
        cleaners = this.cleaning.cleaners
          .map((cleaner) => this.formatFullName(cleaner))
          .toString();
      }
      return cleaners;
    },
    orderDeliveryInformation() {
      return {
        order_id: this.order.order_id,
        delivery_date: this.order.deliver_to_customer_date,
        location_area: this.order.location_area,
        address: this.order.home_address,
        phone_number: this.order.phone_number,
      };
    },
  },
  created() {
    this.setCleaning();
    this.getPreferences();
  },
  methods: {
    setCleaning() {
      const {
        cleaning_type,
        home_address,
        sp_cleaning,
        item_areas,
        room_size,
        cleaners_id,
        cleaners,
        primary_cleaner_id,
        primary_cleaner,
      } = this.order;
      this.cleaning.customerId = this.customerId;
      this.cleaning.cleaning_type = cleaning_type;
      this.cleaning.cleaners_id = cleaners_id || [];
      this.cleaning.cleaners = cleaners || {};
      this.cleaning.primary_cleaner_id = primary_cleaner_id || null;
      this.cleaning.primary_cleaner = primary_cleaner || {};
      this.cleaning.home_address = home_address;
      this.cleaning.sp_cleaning = sp_cleaning;
      this.cleaning.sp_name = this.cleaningServicePartners[sp_cleaning]?.name;
      this.cleaning.cleaning_notes = "";
      this.cleaning.item_areas = item_areas;
      this.cleaning.room_size = room_size
        ? room_size
        : Object.keys(item_areas ?? {}).length > 0
        ? Object.keys(item_areas).reduce((total, area) => {
            return total + item_areas[area];
          }, 0)
        : 0;
    },
    getPreferences() {
      order.orderPreferences(this.order.order_id).then((response) => {
        if (response.data.status) {
          let notes;
          notes = response.data.order_general_preference.length
            ? response.data.order_general_preference
            : [];
          if (notes.length) {
            this.cleaning_notes = [...notes];
            this.cleaning.cleaning_notes = notes[0].preference;
          }
          // notes  = response.data.order_specific_preference.length ? response.data.order_specific_preference :[]
          // if (notes.length){
          //   this.cleaning_notes = [...this.cleaning_notes,...notes]
          // }
        }
      });
    },
    updateCleaning(cleaning) {
      this.$nextTick(() => {
        this.cleaning = {
          ...this.cleaning,
          ...cleaning,
          cleaners: cleaning.cleaners_id,
        };
        this.editing = false;
        this.cleaning_notes = [{ preference: cleaning.cleaning_notes }];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title-section {
  margin-top: 15px;
}

.extra-notes {
  h6 {
    margin-bottom: 12px;
  }
}
</style>
